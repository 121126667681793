<template>
  <section class="careers-quote ds-background-color-black">
    <div class="ds-container">
      <div class="ds-grid">
        <div class="ds-grid__col ds-grid__col--no-gutter ds-grid__col--12 ds-md-grid__col--5 ds-lg-grid__col--6">
          <div class="careers-quote__image-box">
            <DsImageElement
              v-if="quote.image"
              :image="quote.image"
              :screen="$screen"
              class="careers-quote__image-box__image"
            />
          </div>
        </div>
        <div
          class="
            ds-grid__col ds-grid__col--12 ds-md-grid__col--7 ds-lg-grid__col--5 ds-lg-grid__col--offset-1
            ds-padding-y-48 ds-md-padding-y-96
          "
        >
          <p
            class="ds-heading-small ds-md-heading ds-font-weight ds-color-white"
            v-text="quote.quote"
          />
          <p
            class="ds-text-medium ds-color-white ds-margin-top-12"
            v-text="quote.author"
          />

          <DsLink
            v-if="quote.link"
            :url="quote.link.url"
            :open-in-new-window="quote.link.openInNewWindow"
            :nuxt="quote.link.nuxt"
            :content="quote.link.title"
            :icon-right="{ category: 'arrows', name: 'chevron-right-small' }"
            class="ds-margin-top-24"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { DsLink, DsImageElement } from 'awds'
const { $screen } = useNuxtApp()

defineProps({
  quote: {
    type: Object,
    required: true
  }
})
</script>

<style lang="scss" scoped>
@import 'awds/foundation-vars.scss';
@import 'awds/foundation-mixins.scss';

.careers-quote {
  position: relative;

  &__image-box {
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;

    &__image {
      height: auto;
      width: 100%;
    }

    @include screen('medium') {
      left: 0;
      position: absolute;
      width: 41.6666666667%;

      &__image {
        height: 100%;
        width: auto;

        :deep() img {
          height: 100%;
          width: auto;
        }
      }
    }

    @include screen('large') {
      width: 50%;
    }
  }
}
</style>
